import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class RafflesPage extends Component {
	render() {
        const imgs = [
            <img src="/imgs/halls/raffles/raffles1.jpg" alt="" className="img-center" />,
            <img src="/imgs/halls/raffles/raffles2.jpeg" alt="" className="img-center" />,
        ];

        return (
            <div>
                <ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>Raffles</h1>
					{/*
					<p>Raffles Christian Fellowship (RCF) aims to form a community of believers within Raffles Hall, where we can support one another in our walk with God and grow in faith together. As an RCF family, we aim to be the salt and light of RH and forge God-centred friendships, look out for each other and encourage each other in truth and love to live for Christ. We gather every week for a time of fellowship, worship, prayer and a short bible study.</p>
					<p>We meet every Thursday night!  Please feel free to join us!</p>
					*/}

					<p>
						RCF is a homely and welcoming place where we support one another in our walk with God and grow in Him :) we also aim to show love to our community in Raffles Hall! feel free to join us! message us to learn more!
					</p>

					<p>
						We meet weekly for a time of worship, bible discussion and prayer!
					</p>

					<p>
						Contact us at:<br/>
						@victorwongyd<br/>
						@joicheng23
					</p>
				</div>
			</div>
		);
	}
}

export default RafflesPage;