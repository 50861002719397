import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
// import CarouselSlides from "../CarouselSlides";

class ArtsPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				{/* <CarouselSlides /> */}
				<div className="container">
					<h1>Arts</h1>
					<p>Arts CF is full of fun people! We’re interesting and the most diverse group on campus – we come from many different majors: from scienc-y/math-y Psychology/Econs, to arty-fartsy Literature!</p>
					<p>Arts CF’ers have weekly Contact Group meetings on either Monday, Wednesday or Thursday; usually from 6pm (for dinner) till about 930pm. We worship, pray and study the bible together during our meets.</p>
					<p>We have PLUMB groups (Pray, love, understand my buddy), consisting of roughly 4 to 5 people. These smaller groups are set up by our lovely Contact Group Leaders and the groups meet regularly, to befriend each other and provide more accountability in our faiths.</p>
					<p>Morning Prayer also happens daily at the Deck; from 9am to (over) 10am. We pray for each other, and for things beyond ourselves! We also have interesting discussions, reflections and devotions during Morning Prayer – e.g. questioning what prayer is.</p>
					<p>This year, Arts CF will have quarterly outreach events, to put what we’ve learnt into action. Our outreach events are in line with this year’s CF direction: ‘going out to see’ – being aware of the community around us, and hopefully seeking to serve the community – and ‘coming back to be’ – incorporating the things we’ve learnt in our lives and sharing it with others.
						<br />If you’ve any questions or want to join us, reach us at <a href="mailto:nusartsvcf@gmail.com">nusartsvcf@gmail.com</a> or connect with us on facebook at NUS VCF @ Arts!</p>
				</div>
			</div>
		);
	}
}

export default ArtsPage;