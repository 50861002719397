import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class SciencePage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/faculties/science/science2.jpeg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/science/science3.jpeg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/science/science4.jpeg" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>Science</h1>
					{/*
					<p>Through the understanding that God’s love is unchanging and everlasting, we aim to grow closer together as one body, while exploring deeper into the Word, so that we may be able to reap fruits in our hearts and form a more meaningful relationship with God. </p>
					<p>As a group of university students, Science CF is a place for open conversations about issues in the world and in church today. One may freely ask questions about the Bible and the faith that may not be widely covered in church. </p>
					<p>This academic year, we would like to explore what it means to be a 'Christian student', in terms of our studies and our rest. We are also called to be salt and light wherever we are, including on this campus that God has brought us to! Hence, we seek to spur and encourage each other to be His witnesses in this campus.</p>
					<p>We hope to be a community that you can find support, love and growth in. Come journey with us as we seek God's grace and mercy together!</p>
					<p>Contact Jodie @<a href="tel:97630518">97630518</a> or email us at <a href="mailto:nusvcfscience@gmail.com">nusvcfscience@gmail.com </a> if you like to know more!</p>
					<p><b>Activities:</b></p>
					<p><u>Weekly CG Meetings</u>
					<br />Every Tuesday, 7 pm – 9.30 pm at University Sports Centre/Faculty of Science (TBC)</p>
					<p>During our weekly meetings, we eat dinner at 6 pm and gather to worship God, study the Bible and pray for each other.</p>
					<p><u>Prayer and Support Groups (PSGs)</u>
					<br />During the start of the semester, we split ourselves into groups of 3-4 members. These groups act as a pillar of support during the academic year, while also posing as a reminder to not prioritize academics before God.</p>
					<p>Each PSG meets at their own time, where they study together, eat together, and grow together. Each group will also consist of at least one senior and one junior, allowing for a mentor relationship to form. Those who cannot make it on Tuesdays can still join a PSG!</p>
					*/}

					<p>
						Science CF is a warm and welcoming family that journeys with one another through the rigour of University, and is a safe place for us to share our struggles and joy! Through the love and grace of God, we strive to grow closer together as a community rooted in Christ, while exploring deeper into the word so that we may reap fruits in our hearts and form meaningful relationships with each other and with God.
					</p>

					<p>
						As a christian fellowship in the science faculty, we also aim to provide an open and conducive environment for discussions about science and Christianity, and issues surrounding the church and society. We study the word together and spur one another to be Christ-like in University and everywhere we go, so that we may be the salt and light God called us to be!
					</p>

					<p>
						This new academic year we would like to explore what it means to form a community rooted in God, and to be intentional about the connections we make with one another, especially in the context of Covid-19. We strive to keep each other accountable in our walks with God too! 
					</p>

					<p>
						Come join Science CF, we welcome each and everyone of you with open arms!
					</p>

					<p>
						See what we do @nussciencecf on instagram <br/>
						Contact Yi Tang on telegram or whatsapp @yitangg/86667364 <br/>
						You can also email us at <a href="mailto:nusvcfscience@gmail.com">nusvcfscience@gmail.com</a>
					</p>

					<p>
						Activities
					</p>

                    <p><b>Contact Group (CGs)</b><br />
					We have CGs every tuesday from 7pm-9pm<br />
					During our CGs, we come together to worship God, study the bible and pray for each other!<br /><br />

					Join us earlier at 6pm to have dinner together!
					</p>

                    <p><b>Prayer Support Group (PSG)</b><br />
					At the start of the academic year, we split ourselves up into groups of 3-4. These groups act as a pillar of support for one another throughout the AY. PSGs come together to fellowship and chill, and there is really no set formula for how a PSG is held, and each PSG is unique!<br /><br/>

					Through PSG we strive for our members to grow closer to one another and to create a safe community where we can share our struggles and joys, and encourage each other to always pray even in the good times and the bad.
					</p>

				</div>
			</div>
		);
	}
}

export default SciencePage;