import React, { Component } from 'react';
import './Faculties.css';
import { Link } from 'react-router-dom';
import ScrollToTop from "./Components/Utils/ScrollToTop";
// import { Link as ScrollLink } from 'react-scroll'

/**
 * Displays overview of various faculties.
 * 
        <div id="faculties-overview-description">
        </div>

        <div id="faculties-overview-description">
        </div>
 */
class Ministry extends Component {
    render() {
        return (
            <Link className={this.props.minType} to={this.props.link}>
                <button class="category-button">{this.props.name}</button>
            </Link>
        );
    }
}

class HallMinistry extends Component {
    render() {
        return (
            <Link className={this.props.minType} to={this.props.link}>
                <button class="hall-category-button">{this.props.name}</button>
            </Link>
        );
    }
}

const MinistryFac = (props) => {
    return <Ministry {...props} minType="fac" />;
};

const MinistryHostel = (props) => {
    return <HallMinistry {...props} minType="hostel" />;
};

const MinistryAffiliate = (props) => {
    return <Ministry {...props} minType="affiliate" />;
};

export const FacultiesOverview = () => (
    <div class="faculties-overview">
        <ScrollToTop />
        <div class="category-header">
            FACULTIES
        </div>
        <div class="category-description">
            We come from an array of majors and we enjoy meeting up for fellowship!
        </div>
        <div class="faculty-category-container">
            {/*A total of 8 CFs */}
            {/*
            <button class="category-button">Arts</button>
            <button class="category-button">BizCom</button>
            <button class="category-button">Dentistry</button>
            <button class="category-button">Engineering</button>
            <button class="category-button">Law</button>
            <button class="category-button">MedNurse</button>
            <button class="category-button">Science</button>
            <button class="category-button">SDE</button>
            */}

            <MinistryFac
                name="Arts"
                link="/faculties/arts"
            />
            <MinistryFac
                name="BizCom"
                link="/faculties/bizcom"
            />
            <MinistryFac
                name="Dentistry"
                link="/faculties/dentistry"
            />
            <MinistryFac
                name="Engineering"
                link="/faculties/engineering"
            />
            <MinistryFac
                name="Law"
                link="/faculties/law"
            />
            <MinistryFac
                name="MedNurse"
                link="/faculties/mednurse"
            />
            <MinistryFac
                name="Science"
                link="/faculties/science"
            />
            <MinistryFac
                name="SDE"
                link="/faculties/sde"
            />
        </div>
    </div >
);

// Proud to present our affiliate ministries!
export const AffiliateMinistriesOverview = () => (
    <div class="faculties-overview">
        <ScrollToTop />
        <div class="category-header">
            AFFILIATE MINISTRIES
        </div>
        <div class="category-description">
            Our extended VCF family!
        </div>
        <div class="affiliate-ministries-category-container">
            {
              /*A total of 3 ministries
            <button class="category-button">Chinese VCF</button>
            <button class="category-button">Indonesian Students' CF</button>
            <button class="category-button">Post-Graduate CF</button>
            */}
            <MinistryAffiliate
                name="Chinese VCF"
                link="/affiliates/cvcf"
            />
            <MinistryAffiliate
                name="Indonesian Students' CF"
                link="/affiliates/iscf"
            />
            <MinistryAffiliate
                name="Post-Graduate CF"
                link="/affiliates/pgcf"
            />
        </div>
    </div >
);


// We meet in cool places where we stay!
export const HallMinistriesOverview = () => (
    <div class="faculties-overview">
        <ScrollToTop />
        <div class="category-header">
            HALLS & RESIDENCES
        </div>
        <div class="category-description">
            For those staying on campus!
        </div>
        <div class="halls-category-container">
            {/*
            <button class="category-button">Eusoff</button>
            <button class="category-button">PGP</button>
            <button class="category-button">Kent Ridge</button>
            <button class="category-button">King Edward</button>
            <button class="category-button">Raffles</button>

            <button class="category-button">RVRC</button>
            <button class="category-button">Sheares</button>
            <button class="category-button">Temasek</button>
            <button class="category-button">USP</button>
            <button class="category-button">UTOWN</button>
            */}
            <MinistryHostel
                name="Eusoff"
                link="/halls-residences/eusoff"
            />
            <MinistryHostel
                name="ICF @ PGP"
                link="/halls-residences/pgp"
            />
            <MinistryHostel
                name="Kent Ridge"
                link="/halls-residences/kentridge"
            />
            <MinistryHostel
                name="King Edward"
                link="/halls-residences/kevii"
            />
            <MinistryHostel
                name="Raffles"
                link="/halls-residences/raffles"
            />
            <MinistryHostel
                name="RVRC"
                link="/halls-residences/rvrc"
            />
            <MinistryHostel
                name="Sheares"
                link="/halls-residences/sheares" />
            <MinistryHostel
                name="Temasek"
                link="/halls-residences/temasek"
            />
            <MinistryHostel
                name="USP"
                link="/halls-residences/usp" />
            <MinistryHostel
                name="UTown"
                link="/halls-residences/utown"
            />
        </div>
    </div >
);
