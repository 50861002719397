import CarouselSlides from "../CarouselSlides";
import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class PGPPage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/halls/pgp/icf1.JPG" alt="" className="img-center"/>,
			<img src="/imgs/halls/pgp/icf2.JPG" alt="" className="img-center"/>,
			<img src="/imgs/halls/pgp/icf3.JPG" alt="" className="img-center"/>,
			<img src="/imgs/halls/pgp/icf4.JPG" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<div className="container">
					{/* <h1>International CF @ Prince George's Park Residences</h1> */}
					<h1>ICF (International CF)</h1>
                    <div style={{ marginBottom: 60, marginTop: 30 }}>
						<CarouselSlides content={imgs} />
					</div>
					{/*
					<p>Hello you! </p>
					<p>If you’re an international student or PGP resident looking for a Christian Community on campus, you’re at the right place :) !! </p>
					<p>CG happens every tuesday, from 6pm – 9pm: </p>
					<table>
						<tr>
							<td>6pm – 7pm</td>
							<td>Dinner – Usually either at one of the PGP food courts :)</td> 
						</tr>
						<tr>
							<td>7pm – 9pm</td>
							<td>Worship + Bible Study + Prayer</td>
						</tr>
					</table>
					<p></p>
					<p>We’ve also had weeks where there would be combined supper sessions with EnginCF, outreach with CVCF and our own ICF outings!! :)</p>
					<p>We look forward to having you with us & see you at welcome tea!! :)</p>
					<p>(Drop <a href="https://t.me/staccieee">@staccieee</a> a message on telegram if you would like to join/enquire more about ICF @ PGP)</p>
					*/}

					<p>
						We’re a diverse group of undergraduates from all over the world! When we come together, we learn about each other’s unique journeys and special walks that God has brought us through.
					</p>
					<p>
						Beloved, let us love one another, for love is from God, and whoever loves has been born of God and knows God. [1 John 4:7]
					</p>

					<p>
						Be it an international student on exchange, or a local full time student, we would love to get to know you guys better. Do contact us for more details!<br/>
						Telegram: @danieltwi & @ryanmoey<br/>
						Email: <a href="mailto:danieltanweiian1998@gmail.com">danieltanweiian1998@gmail.com</a> & <a href="mailto:ryanwmmoey@gmail.com">ryanwmmoey@gmail.com</a>
					</p>

				</div>
			</div>
		);
	}
}

export default PGPPage;