import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class StrategyPage extends Component {
    render() {
        return (
            <div>
                <ScrollToTop />
                <div className="container">
                    <h1>Our Vision</h1>
                    <h2>Our Vision</h2>
                    <span className='block-quote'>To make and build disciples of Jesus Christ who will be effective salt and light of the world on campus and in society.</span>
                </div>
            </div>
        );
    }
}

export default StrategyPage;