import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import MinistryThumbnail from './Components/MinistryThumbnails';
import Tabbar from './Components/Tabbar';
import ExcoPage from './Components/Ministries/ExcoPage';
import CVCFPage from './Components/Ministries/CVCFPage';
import ISCFPage from './Components/Ministries/ISCFPage';
import PGCFPage from './Components/Ministries/PGCFPage';
import FESPage from './Components/About Us/FESPage';
import StrategyPage from './Components/About Us/StrategyPage';
import BeliefsPage from './Components/About Us/BeliefsPage';
import ContactUsPage from './Components/ContactUs';
import ArtsPage from './Components/Faculties/ArtsPage';
import OpenPage from './Components/Faculties/OpenPage';
import DentistryPage from './Components/Faculties/DentistryPage';
import EnginePage from './Components/Faculties/EnginePage';
import LawPage from './Components/Faculties/LawPage';
import MeNursePage from './Components/Faculties/MedNursePage';
import SciencePage from './Components/Faculties/SciencePage';
import SDEPage from './Components/Faculties/SDEPage';
import EusoffPage from './Components/Halls/EusoffPage';
import KentRidgePage from './Components/Halls/KentRidgePage';
import KingEdwardVIIPage from './Components/Halls/KingEdwardVIIPage';
import RafflesPage from './Components/Halls/RafflesPage';
import ShearesPage from './Components/Halls/ShearesPage';
import TemasekPage from './Components/Halls/TemasekPage';
import PGPPage from './Components/Halls/PGPPage';
import RVRCPage from './Components/Halls/RVRCPage';
import UtownPage from './Components/Halls/UtownPage';
import USPPage from './Components/Halls/USPPage';
import TAGPage from './Components/Standing Comms/TAGPage';
import BESTPage from './Components/Standing Comms/BESTPage';
import IFGPage from './Components/Standing Comms/IFGPage';
import SEnTPage from './Components/Standing Comms/SEnTPage';
import ScrollToTop from "./Components/Utils/ScrollToTop"
/*
import BlogPage from './Components/Blog/BlogPage';
import PostPage from './Components/Blog/PostPage';
import { AffiliateMinistryCells, HostelMinistryCells, FacultyMinistryCells } from './Components/MinistryCells';
*/

// import HomepageDetails, { CategorySplash } from './HomepageDetails.js';
import { HomepageSplash, WelcomeSplash, CategorySplash } from './HomepageDetails.js';


import { AffiliateMinistriesOverview, FacultiesOverview, HallMinistriesOverview } from './Faculties.js';

/**
 * Index.js directs to here.
 * Homepage entry point.
 */

/* HOMEPAGE MINISTRY ROWS */ 
class homePage extends Component {
	render() {
		return (
			<div>
				<HomepageSplash/>
				<WelcomeSplash/>
				<CategorySplash />
			</div> 
		);
	}
}

const standingCommPage = () => (
    <div>
        <ScrollToTop />
        <Container>
            <Row>
                <MinistryThumbnail link="/standing-committees/BEST" img="/ministry-thumbnails/BEST.jpg" name="BEST" />
                <MinistryThumbnail link="/standing-committees/IFG" img="/ministry-thumbnails/IFG.jpg" name="IFG" />
            </Row>
            <Row>
                <MinistryThumbnail link="/standing-committees/TAG" img="/ministry-thumbnails/TAG.jpeg" name="TAG" />
                <MinistryThumbnail link="/standing-committees/SEnT" img="/ministry-thumbnails/SEnT.jpg" name="SEnt" />
            </Row>
        </Container>

        <div className="container">
            <h1>Standing Committees</h1>
            <p>
                The Standing Committees we know today are BEST, SEnT, TAG and IFG. While it may seem that these Standing
                Committee serve a functional role within VCF, to fully understand a standing comm’s purpose, we must
                consider their genesis and evolution. Standing Commss are not fixed structures within VCF. Of the 4 Standing
                comms, only IFG has a history of more than 6 years. The other two, BEST and SEnT were conceived in 2012 and
                2013 respectively while LTP has just relaunched this year.
		</p>
            <p>
                To understand the thought process behind the creation of standing committees, consider the reports of the
                pioneering Chairs of BEST and SEnT:
		</p>
            <p>
                “The Bible Engagement Study Team (BEST) was started in 2012 when a group of students expressed their
                eagerness to commit to a long-term group focused on exploring the state of biblical literacy among fellow
                students. Through the sessions, the students had the opportunity to re-engage with biblical texts and to
                apply what they had learned from Inductive Bible Study sessions…” – AGM Report 2012/2013
		</p>
            <p>
                “(the Social Engagement Table): ‘as a place or community where people come to network, discuss and plan with
                regards to Christian social engagement, and also readily leave to act’. This idea was expanded upon to
                imagine a space, rather than a committee, within VCF where Christian social engagement—discussion, the
                throwing out of ideas, thoughts—could be imagined and conceptualized. It was a space which was invitational,
                flexible, porous and open: where VCFers “come to the table”, learn from one another about what it means to
                engage, and how, and then leave to act/transform with their hearts and minds both conscientized and
                convicted.” –AGM Report 2013/2014
		</p>
            <p>
                We see that standing committees were always formed with the larger body of CF in mind. Standing comms are
                formed when a group of students felt a need to champion or explore a particular aspect of Christian living.
                In a sense, standing committees seek to live beyond the boundaries of the status quo and encourage the rest
                of us to do the same through their activities and interactions. A common way to understand Standing comm’s
                functions in VCF is addressing blind-spots. Anyone who has learnt how to drive knows that checking you blind
                spot regularly is a key feature of safe and responsible driving. However, anyone who has been driving for a
                while knows that checking the blind spot is something drivers rarely do. As VCF continues to grow and move
                forward, we, like complacent drivers, may check our blind spots less often. Thus, it is the role of these
                standing committees to keep VCF in check. To steer conversation back to things that are easily neglected.
                When conversations revolve around a superficial applications of bible verses, BEST challenges us to go deep.
                Where we become desensitized to the foreign students sitting right besides us, IFG agitates our perceptions
                to consider these students as friends. While we are bombarded with a myriad of social issues that need to be
                tackled, SEnT pulls our motivations back to biblical foundations and spurs us on to social action.
		</p>
            <p>
                Reader, you may find that there are many blind spots that do not have standing committees to champion them.
                Indeed, it is the nature of our student-initiated ministry for us to recognize these blind spots and own the
                responsibility to adequately and responsibly address them.
		</p>
            <p>
                On a more practical note, if you or anyone you know feel strongly for the existing purposes of the standing
                comms or feel strongly about a blind spot that is neglected, please approach the standing comms themselves
			or reach out to the EXCO at the <a href="mailto:vcfsecretary@gmail.com">vcfsecretary@gmail.com</a>.
		</p>
        </div>
    </div>
);

const RoutingFilePaths = () => (
	<div>
		<Route exact path="/" component={homePage} />
		<Route exact path="/vision" component={StrategyPage} />
		<Route exact path="/core-beliefs-structure" component={BeliefsPage} />
		<Route exact path="/fes" component={FESPage} />

		<Route exact path="/faculties" component={FacultiesOverview} />
		<Route exact path="/halls-residences" component={HallMinistriesOverview} />
		<Route exact path="/affiliates" component={AffiliateMinistriesOverview} />

		<Route exact path="/standing-committees" component={standingCommPage} />

		<Route exact path="/affiliates/pgcf" component={PGCFPage} />
		<Route exact path="/affiliates/iscf" component={ISCFPage} />
		<Route exact path="/affiliates/cvcf" component={CVCFPage} />

		<Route exact path="/exco" component={ExcoPage} />
		<Route exact path="/contact-us" component={ContactUsPage} />
		<Route exact path="/faculties/arts" component={ArtsPage} />
		<Route exact path="/faculties/bizcom" component={OpenPage} />
		<Route exact path="/faculties/dentistry" component={DentistryPage} />
		<Route exact path="/faculties/engineering" component={EnginePage} />
		<Route exact path="/faculties/law" component={LawPage} />
		<Route exact path="/faculties/mednurse" component={MeNursePage} />
		<Route exact path="/faculties/science" component={SciencePage} />
		<Route exact path="/faculties/sde" component={SDEPage} />
		<Route exact path="/halls-residences/eusoff" component={EusoffPage} />
		<Route exact path="/halls-residences/kentridge" component={KentRidgePage} />
		<Route exact path="/halls-residences/kevii" component={KingEdwardVIIPage} />
		<Route exact path="/halls-residences/raffles" component={RafflesPage} />
		<Route exact path="/halls-residences/sheares" component={ShearesPage} />
		<Route exact path="/halls-residences/temasek" component={TemasekPage} />
		<Route exact path="/halls-residences/pgp" component={PGPPage} />
		<Route exact path="/halls-residences/rvrc" component={RVRCPage} />
		<Route exact path="/halls-residences/utown" component={UtownPage} />
		<Route exact path="/halls-residences/usp" component={USPPage} />
		<Route exact path="/standing-committees/TAG" component={TAGPage} />
		<Route exact path="/standing-committees/BEST" component={BESTPage} />
		<Route exact path="/standing-committees/IFG" component={IFGPage} />
		<Route exact path="/standing-committees/SEnT" component={SEnTPage} />
		{/*
		<Route
			exact
			path="/blog/tags/:tag"
			render={(props) => {
				return <BlogPage key={props.match.params.tag} {...props} />;
			}}
		/>
		<Route exact path="/blog" component={BlogPage} />
		<Route exact path="/blog/post/:id" component={PostPage} />
		*/}
	</div>
);

class App extends Component {
	constructor(props) {
		super(props);
		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true,
			activeTab: 'home'
		};
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	/*
	<Tabbar toggleTab={this.toggleTab.bind(this)} activeTab={this.state.activeTab} />
	 */
	render() {
		return (
			<div>
	<Tabbar toggleTab={this.toggleTab.bind(this)} activeTab={this.state.activeTab} />
				<RoutingFilePaths/>
			</div>
		);
	}
}

export default App;
