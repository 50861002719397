import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class KingEdwardVIIPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>King Edward VII</h1>
					<p>KEVII CF is a community of Christian students in Hall operating under NUS VCF. As a Hall CF, our main aim is to provide a platform of spiritual fellowship and growth. The fellowship focuses on bringing together brothers and sister in Hall to better address any aspects of Hall and student life that each of us may experience. Alongside the vision of NUS VCF, we hope to be a community able to encourage and guide one another in a God-centered campus and Hall experience. Aside from being able to guide and support one another, CF of course aims to be the salt and light within our hostel community, through not only ministry and prayer for our fellow Christians, but also witnessing, extending  our love to  fellow residents wherever possible, and most importantly interceding for the needs of the hostel community.</p>
				</div>
			</div>
		);
	}
}

export default KingEdwardVIIPage;