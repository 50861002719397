import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class RVRCPage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/halls/rvfc/carousel/pic1.png" alt="" className="img-center"/>,
			<img src="/imgs/halls/rvfc/carousel/pic2.png" alt="" className="img-center"/>,
			<img src="/imgs/halls/rvfc/carousel/pic3.png" alt="" className="img-center"/>,
			<img src="/imgs/halls/rvfc/carousel/pic4.png" alt="" className="img-center"/>,
		];
		return(
			<div>
				<CarouselSlides content={imgs}/>
				<ScrollToTop />
				<div className="container">
					<h1>Ridge View Residential College Christian Fellowship</h1>
					<p>Hello there! </p>
					<p>Due to the nature of hostels where there are a lot of activities happening at night, our CF sessions’ timings might range differently week :) </p>
					<p>Things done during the CF session might also range as well! (We’re really flexible when it comes to this – that means if you have something in mind you would like to share/do, we’re super open to it!) As a whole, we would generally would have a time of Worship, Bible Study, Prayer & Outreach occasionally :)</p>
					<p>We look forward to having you join us amidst the hustle and bustle of Hostel life :)</p>
					<p>(Drop  <a href="https://t.me/staccieee">@staccieee</a> a message on telegram if you would like to join/enquire more about RVCF)</p>
				</div>
			</div>
		);
	}
}

export default RVRCPage;