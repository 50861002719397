import React, { Component } from "react";
import { Media } from "reactstrap";

class FESPage extends Component {
	render() {
		return(
			<div className="container">
				<Media>
					<Media left top>
						<img src={require("../../img/FESLogo.png")} alt=""/>
					</Media>
					{/* <Media body>
						<Media heading>Fellowship of Evangelical Students</Media>
						<Media heading>基督徒学生福音团契</Media>
					</Media> */}
				</Media>
				<br />
				<p>FES is an interdenominational Christian organisation serving among tertiary-level students and graduates in Singapore. Our vision is to see a community of Christlike leaders who are salt and light on campus, in church, and in society for the glory of God.</p>
				<p>FES was formed in 1959 to coordinate and support the growing student ministry across the different campuses in Singapore. Today, we have an established student witness in most of the public universities and polytechnics, as well as some of the private tertiary institutes in Singapore. In line with the pioneering emphasis of FES to develop a Christian student witness in every institution of higher learning, we are currently involved in pioneering work in some institutions as well.</p>
				<p>For more information, visit: <a href="http://www.fessingapore.org/">http://www.fessingapore.org/</a></p>
			</div>
		);
	}
}

export default FESPage;