import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
// import CarouselSlides from "../CarouselSlides";

class DentistryPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				{/* <CarouselSlides /> */}
				<div className="container">
					<h1>Dentistry</h1>
					<p>Dent CF is a young ministry that took root in 2018 after many years of prayer, and a vision cast by one of the previous VCF Chairmen. Despite it's youth, Dent CF is a very blessed ministry, populated by dental students passionate for Christ and with a desire to be effective salt and light wherever they are. </p>
					<p>Our CF meets on Thursday evenings, for dinner at 6pm and starting CG at 7pm. CGs are a time for worship, for topical discussions or bible studies, and for sharing with and praying for each other.</p>
					<p>We also meet for Morning Worship on Mondays at 730am to set our minds on Christ, and commit the entirety of the week to him. </p>
					<p>Additionally, we also meet with the wider Christian body in dentistry every last Thursday of the month at the Dental Prayer Fellowship, receiving perspectives from and praying together with Christian dentists of all ages.</p>
					<p>Contact us at <a href="mailto:dentcf@gmail.com">dentcf@gmail.com</a> to get to know us better!</p>
				</div>
			</div>
		);
	}
}

export default DentistryPage;