import React, { Component } from 'react';
import ScrollToTop from "./Utils/ScrollToTop"

class ContactUsPage extends Component {
    render() {
        return (
            <div>
                <ScrollToTop />
                <div className="container" style={ContactText}>
                    
                    <h1>Contact Us</h1>
                    <p>Dear VCFer,</p>
                    <p>
                        if you are looking for information on the various faculties, halls and standing Committees, please
					feel free to  <a href="mailto:vcfsecretary@gmail.com">
                            drop us an email at vcfsecretary@gmail.com
					</a>{' '}
					with your name and email and a message stating which ministry you are interested in and we will be
					in touch with you as soon as possible.
				</p>
                </div>
            </div>
        );
    }
}

const ContactText = {
    fontFamily: "Verdana",
    fontSize: "23px"
}

export default ContactUsPage;
