import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class EnginePage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/faculties/engineering/engin1.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/engineering/engin2.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/engineering/engin3.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/engineering/engin4.jpg" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>Engineering CF</h1>
					<h6><b>Character & Faith, Triumph in Adversity</b></h6>
					{/*
					<p><h3><b>Direction:</b> Seeking God, Loving people</h3></p>
					<p>“He has told you, O man, what is good; and what does the Lord require of you but to do justice, and to love kindness, and to walk humbly with your God?” Micah 6:8 ESV</p>
					<p>This is the call of a disciple of Christ to be witnesses to all nations and in our context, the campus on Engineering Faculty.</p>
					<p><b>Seeking God:</b>
						<br />EnginCF is a very bonded and tight knitted family of Christians of different churches and denominations, to actively support each other emotionally and spiritually in our journey with Christ here on Campus.</p>
					<p><b>Loving People:</b>
						<br />EnginCF aims to bless our engineering community through outreach by loving them in active measures.</p>
					<p>We warmly welcome you to join us in the following activities:</p>
					<p><u><b>Weekly Contact Groups (CGs)</b></u>
						<br />Held every Tuesday evenings from 6pm – 9.30pm where we gather for a time of worship and to study deeper into the word, where we will be challenged on what it means to be a Christian on campus.</p>
					<p><u><b>Fellowship @ Macs</b></u>
						<br />A time where we can bond over Fellowship of FOOD after each CG session to chill in an extended time of discussion.</p>
					<p><u><b>Recharge (Prayer) Groups</b></u>
						<br />Recharge groups are smaller groups where EnginCF members will gather in their own time. These groups acts as a pillar of support for EnginCF members outside CG time, to pray together and know each other better.</p>
					<p><u><b>Fellowship Teachings (FTs)</b></u>
						<br />Held on the 2nd Tuesday of each month where the entire VCF Body will gather for a worship and a message.</p>
					<p>Contact us at <a href="mailto:vcf.engine@gmail.com">vcf.engine@gmail.com</a></p>
					*/}

					<p>
						EnginCF exists as a community of like-minded and loving believers who are passionate about pursuing Christ, fellowship and everything innovative. 
						We share a common love for the Word and the corporate aspect of edification through small group meetings, prayers and collective worship.
					</p>

					<p>
						This Academic Year, we seek to journey our way through an unprecedented health crisis by leaning upon God’s provision
						- which is Faith, and endeavour to mould every enginCFer into young men and women who possess holy distinctive qualities that separates us from mere civility;  
						this is Character.
					</p>
					<p>
						Our meetings every Tuesday (7-9pm) will be held online via Zoom, with designed worship sessions recorded by seniors, interactive breakout sessions learning from the Book of Luke
						, and occasional Quizzes & Games for bonding.
					</p>
					<p>
						In addition, you can expect to join us on other events such as:
					</p>
					<p><u><b>Open Dialogue Sessions</b></u>
						<br />This is a seminar-style talk where we discuss philosophical topics such as ethics, and themes related to Engineering. More details will be released on our IG page.
					</p>

					<p><u><b>Weekly ‘Recharge’ Meetings</b></u>
						<br />In addition to our combined sessions every Tuesday, you will be specially allocated to a smaller group together with 3-4 friendly seniors/fellow freshmen to pursue fellowship & have casual conversations! 
						This will be held online for the upcoming AY due to covid-19.
					</p>

					<p><u><b>Recess and Reading Week Hangouts</b></u>
						<br />
						In EnginCF, we see the importance of building genuine relationships with one another, not just during formal meeting hours. We hope to cultivate a space for joy and laughter during term breaks such as Recess Week by holding mini games and activities (via Zoom) during the cohesion.
						<br />
						<br/>
						Lastly, EnginCF would like to convey the message that regardless of how unworthy you may feel on certain days, or see yourself as someone who’s shy and introverted, or you would eventually join us late into the semester; the doors of EnginCF will always be open for you; we love & welcome you just as Jesus does.
					</p>

					<img src="/imgs/faculties/engineering/engin_info.png" alt="" className="img-center"/>

					<p><b>Contact Larenz @Thylegend27 on Telegram<br/>Visit us on Instagram @nusengincf</b></p>
				</div>
			</div>
		);
	}
}

export default EnginePage;