import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class EusoffPage extends Component {
    render() {
        const imgs = [
            <img src="/imgs/halls/eusoff/eusoff1.jpg" alt="" className="img-center" />,
            <img src="/imgs/halls/eusoff/eusoff2.jpeg" alt="" className="img-center" />,
            <img src="/imgs/halls/eusoff/eusoff3.jpeg" alt="" className="img-center" />,
            <img src="/imgs/halls/eusoff/eusoff4.jpeg" alt="" className="img-center" />,
		];

		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>Eusoff CF</h1>
                    <div style={{ marginBottom: 60, marginTop: 30 }}>
						<CarouselSlides content={imgs} />
					</div>
					{/*
					<p>Understand and meditate on God's word together with people living in the same hall. More than building your spiritual need, we aim to help form a strong spiritual family in Eusoff to help on another in our personal walk with God in hall and at the same time reach out to fellow Eusoffians. We pray that you will find God even in hall and continue to walk close to Him amidst all your busy schedules. To also be on fire for God and continue in your pursuit of Him.</p>
					<h2>Activities:</h2>
					<p>We will have activities such as Bible study, exam blessings and prayer sessions</p>
					*/}

					<p>
						ECF is a cozy community welcoming to believers and pre-believers alike. We gather to worship, pray, and learn more from the Word together, building friendships that last even outside of these sessions. In addition to growing in our individual walks with God, we aim to be able to reach out to fellow Eusoffians through extending God's love and care to them as well. Join us to find other like-minded siblings in Christ as we journey with one another through the busyness of hall and school life!
					</p>

					<p>
						For any other queries, feel free to contact Claudia or Celine on Telegram at @claudialwy or @yeowww respectively!
					</p>
				</div>
			</div>
		);
	}
}

export default EusoffPage;