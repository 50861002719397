import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CarouselSlides extends Component {
	render() {
		var settings = {
			dots: true,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			autoplay: true,
			speed: 2000,
			autoplaySpeed: 5000,
			cssEase: "linear",
			overflow: "hidden",
		};
		let slides = [];
		
		// Ignoring single images.
		if (this.props.content.length > 1) {
			for(var i = 0; i < this.props.content.length; i++) {
				slides.push(
					<div className="slides">
						{this.props.content[i]}
					</div>
				);
			}
		}	

		return (
			<Slider {...settings}>
				{slides}
			</Slider>
		);
	}
}

export default CarouselSlides;