import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class UtownPage extends Component {
	render() {
        const imgs = [
            <img src="/imgs/halls/utown/utown1.jpg" alt="" className="img-center" />,
        ];

        return (
            <div>
                <ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>University Town</h1>
					{/*
					<p>VCF in University Town seeks to gather likeminded Christians within the four residential colleges: Residential College 4, College of Alice and Peter Tan, Cinnamon College and Tembusu College. Its primary aim is the emphasis on the central role Christ in our lives through the deepening in our walk with God and strengthening the fellowship amongst believers. Our vision this year: A growing community of Christians whose hearts and minds are aligned with Christ.</p>
					<p>We aim to achieve this through a wide spectrum of activities within and beyond our weekly Contact Group (CG). The hope is that we become encouraged in Christ though bible studies, songs of praise and worship and by each other- weekly breakfast prayer meets, informal outings and sports. This encouragement spurs us to continually seek to align our hearts and minds to Christ; to share in his burdens for mankind, to spark in us our love others for others, just as Christ has first loved us.</p>
					<p>One of the distinctive of Utown VCF is that it is the bridge between faculties. Christians who attend Utown CG are from diverse faculties, fostering cross interaction and deeper appreciation of others. Through this, we exemplify the uniqueness of the body of Christ-each member being diverse yet having a role to play, not just within the Christian community, but the wider university community as well.</p>
					<p>"That their hearts may be encouraged, being knit together in love, to reach all the riches of full assurance of understanding and the knowledge of God's mystery, which is Christ, 3 in whom are hidden all the treasures of wisdom and knowledge.” Colossians 2:2-3 (ESV)</p>
					*/}

					<p>
						You may be wondering: <b>What is so unique about UTown Varsity Christian Fellowship (VCF)?</b>
					</p>

					<p>
						UTownVCF is probably the ministry on campus where you can probably find the most diverse demographic of students, with members coming from various faculties and even residential colleges.
					</p>

					<p>
						UTownVCF contact group sessions are usually held on <b>Thursdays</b> at <b>8.30 pm</b> (but please feel free to join us for our pre-CG dinner jios over zoom at 7.30 pm, where we can have extra time to fellowship together!) During our weekly CG sessions, we will have a time of worship, bible study or topical discussions and prayers.
					</p>

					<p>
						Something that we hope our fellow UTownVCF members can choose to put on their hearts this upcoming academic year is to rejoice in the presence of God wherever they may be and whenever they can.
					</p>

					<p>
						<b>Want to know more about UTownVCF?</b>
					</p>

					<p>
						Come join our UTownVCF family, made up of loving brothers and sisters in Christ, which can keep you grounded in your faith as you are wading through the unchartered waters of your University journey! We welcome you with wide open arms and hope to see you! :”)
					</p>

					<p><b>
						You may also contact Rachel @rachelminqi or Shermaine @picodegalloo on Telegram for any further queries about UTownVCF!
					</b></p>
				</div>
			</div>
		);
	}
}

export default UtownPage;