import React, { Component } from "react";
import { Media } from "reactstrap";
import ScrollToTop from "../Utils/ScrollToTop";

class ExcoPage extends Component {
	render() {
		return(
			<div className="container">
				<ScrollToTop />
				<Media>
					<Media left top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
					<Media body>
						<Media heading>Chairperson</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
				</Media>
				<Media>
					<Media body>
						<Media heading>Honorary Secretary</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
					<Media right top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
				</Media>
				<Media>
					<Media left top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
					<Media body>
						<Media heading>Honorary Treasurer</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
				</Media>
				<Media>
					<Media body>
						<Media heading>Vice-Chairperson(Faculties)</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
					<Media right top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
				</Media>
				<Media>
					<Media left top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
					<Media body>
						<Media heading>Vice-Chairperson(Halls & Hostels)</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
				</Media>
				<Media>
					<Media body>
						<Media heading>Contact Group Coordinator(Faculties)</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
					<Media right top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
				</Media>
				<Media>
					<Media left top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
					<Media body>
						<Media heading>Contact Group Coordinator(Halls & Hostels)</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
				</Media>
				<Media>
					<Media body>
						<Media heading>Outreach Coordinator</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
					<Media right top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
				</Media>
				<Media>
					<Media left top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
					<Media body>
						<Media heading>Outreach Coordinator</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
				</Media>
				<Media>
					<Media body>
						<Media heading>Teaching & Programs Coordinator</Media>
						Sample text Sample text Sample text Sample text Sample text Sample text
					</Media>
					<Media right top>
						<Media object src="http://www.gstatic.com/webp/gallery/4.jpg" />
					</Media>
				</Media>
			</div>
		);
	}

}

export default ExcoPage;