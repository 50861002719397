import React from "react";
import { Link } from 'react-router-dom';
import './HomepageDetails.css';
import './buttons_2.css';
import { Link as ScrollLink } from 'react-scroll'

/**
 * Refactored from App.js.
 * The homepage image is no longer extended till the edges.
        <ScrollLink class="btn" to="welcome-splash-a" smooth={true} duration={1000}>
 */

export const HomepageSplash = () => (
    <div id="homepage-splash-b">
        <ScrollLink to="welcome-splash-a" smooth={true} duration={1000} style={styles.marginTop}>
            <div class="btn">
                Welcome
            </div>
        </ScrollLink>
    </div>
);

export const WelcomeSplash = () => (
    <div id="welcome-splash-a">
        <div id="test-vision" style={styles.marginEffect} >
            To make and build disciples of Jesus Christ who will be effective salt and light of the
            world on campus and in society.
        </div>
        <ScrollLink to="CategorySplashID" smooth={true} duration={1000}>
            <div class="btn">
                Find out more
            </div>
        </ScrollLink>
    </div>
);


export const CategorySplash = () => (
    <div class="multi-button" id="CategorySplashID" style={styles.a}>
        <Link className={"/"} to={"/affiliates"}>
            <button class="paste">Affiliate<br />Ministries</button>
        </Link>
        <Link className={"/"} to={"/faculties"}>
            <button class="paste">Faculties</button>
        </Link>
        <Link className={"/"} to={"/halls-residences"}>
            <button class="paste">HALLS &<br />RESIDENCES</button>
        </Link>
    </div>
);

/*
			    </span>
                 <a href='/contact-us' id='homepage-cta'>Join Us!</a> <-- COMMENT TTHIS OUT
                <hr />
            </div>
            */
export const HomepageWelcome = () => (
    <div id="homepage-more-info">
        <h2>Hello there!</h2>
        <p>
            We are a Christian student-led ministry based in the National University of Singapore, and
            welcome all NUS students.
					</p>
        <p>
            We have a wide range of activities, events, and camps, all catered towards NUS students and with
            the aim of better understanding and living out our faith in the university context, as well as
            in society.
					</p>
        <p>
            Through various touchpoints, Varsity Christian Fellowship (VCF) aims to help students better
            understand the Bible and examine how our faith interplays with the academics that we pursue in
            school. Armed with the better understanding, we hope to be able to thus be effective salt and
            light, both within our campus and to the society that we live in.
					</p>
        <p>
            Sounds good? We would love for you to join us! Do feel free to{' '}
            <Link to="/contact-us">get in touch with us</Link>!
					</p>
        <hr />
    </div >
);

const styles = {
    background: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: 'auto',
        height: 'auto'
    },
    /*
        width: '100vw',
     
     */
    a: {
        // backgroundImage: "url(" + { background_3 } + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh'
    },
    white: {
        color: 'white'
    },
    marginTop: {
        marginTop: 200
    },
    marginBottom: {
        marginBottom: 60
    },
    marginEffect: {
        marginTop: 90,
        marginBottom: 60
    },
    boxStyles: {
        marginHorizontal: 600,
        display: "flex"
    }
}
