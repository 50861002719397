import React, { Component } from "react";
import CarouselSlides from "../CarouselSlides";
import ScrollToTop from "../Utils/ScrollToTop";

class TAGPage extends Component {
    render() {
        const imgs = [
            // <img src="/imgs/carousel/TAG-1.jpeg" alt="" className="img-center"/>,
            // <img src="/imgs/carousel/TAG-2.jpeg" alt="" className="img-center"/>,
            // <img src="/imgs/carousel/TAG-3.jpeg" alt="" className="img-center"/>,
            // <img src="/imgs/carousel/TAG-4.jpeg" alt="" className="img-center"/>,
            // <img src="/imgs/carousel/TAG-5.jpeg" alt="" className="img-center"/>,
            <img src="/imgs/carousel/TAG-6.jpeg" alt="" className="img-center" />,
        ];
        return (
            <div>
                <ScrollToTop />
                <CarouselSlides content={imgs} />
                <div className="container">
                    <h1>Talking About Graduation (TAG)</h1>
                    <p>"The only certain things in life are death and taxes.' And, one might add for university students, graduation - this inevitable event looms ahead for all undergraduates with a possibly uncertain world thereafter. From seeking employment to managing finances and changing relationships, most are unfortunately forced into belated grappling with these questions while juggling their final year projects or theses.</p>
                    <p>In this vaccum, Talking About Graduation (TAG) was born. Its very name emphasizes the importance of initiative and activity: Instead of merely encouraging solo thought, we seek to initiate conversations among CFers (and the wider student body) on various issues they will have to reckon with as graduation approaches. Guided by the Word and informed by the experiences of those who have walked before us, we believe that time is of essence: Beginning conversations about these topics before one has even reached their final year would give encourage one to plan more seriously for their graduation. In short, we want to help others help themselves.</p>
                    <p>Through these conversations, we hope that a community of like-minded individuals will form and band together, supporting each other as close friends through graduation and thereafter. Through this journeying together in life and bearing the burdens of each other (#Gal 6:2), one might be better able to be the sorely-needed salt and light even after graduation.</p>
                    <p>For AY 2018/2019, we plan to begin with faith and academics before moving on to examine other topics. Such topics may include notions of calling and vocation, the changing nature of relationships during and after graduation as well as disilluionment with one's church upon returning post-graduation.</p>
                    <p>If you would like to be notified of upcoming events or wish to contact us, drop us an email at <a href="mailto:vcf.hashtag@gmail.com">vcf.hashtag@gmail.com</a>!</p>
                </div>
            </div>
        );
    }
}

export default TAGPage;