import React, { Component } from "react";
import CarouselSlides from "../CarouselSlides";
import ScrollToTop from "../Utils/ScrollToTop";

class BESTPage extends Component {
    render() {
        const imgs = [
            <img src="/imgs/standingComms/BEST/pic1.JPG" alt="" className="img-center" />,
            <img src="/imgs/standingComms/BEST/pic2.JPG" alt="" className="img-center" />,
        ];
        return (
            <div>
                <ScrollToTop />
                <CarouselSlides content={imgs} />
                <div className="container">
                    <h1>Biblical Engagement Study Team (BEST)</h1>
                    <p>
                        Hello and welcome to VCF's BEST! We are the Biblical Engagement Study Team (BEST), one of 4 standing committees in VCF.
				</p>
                    <p>
                        BEST is the R&D team for all things Scripture-related, and we seek to encourage a deeper understanding of the Bible among VCFers through creative means!
				</p>

                    <p>
                        We believe that there are many ways to know the Bible and BEST exists to explore these other ways of learning and teaching Scripture.
				</p>
                    <p>
                        This means the topics we deal with will be varied (e.g. Understanding Bible Translations, Hermeneutics, Food in the Bible, Christianese etc.) and where possible, will be paired with <b>experiential activities</b> to reinforce the content shared. This can take a variety of forms: sharing a meal with other VCFers, watching a movie, painting, and SO MUCH more!
				</p>
                    <p>
                        This is a holistic approach to Biblical Engagement (or Scripture Engagement as we like to call it!). Ultimately, we hope that through our activities, VCFers will see how the Word connects with our world, be transformed in both heart and mind, and grow in their relationship with God and with fellow believers.
				</p>

                    <p><i>
                        "But grow in the <b>grace and knowledge of our Lord and Saviour Jesus Christ</b>. To him be glory both now and forever! Amen."  (2 Peter 3:18)
                    </i></p>

                    <p>
                        Get in touch by dropping us a note at  <a href="mailto:vcf.best@gmail.com">vcf.best@gmail.com</a>. To be a BESTie and join our telegram chat, sign up at this link: <a href="https://tinyurl.com/y3muv6mu">https://tinyurl.com/y3muv6mu</a>
                    </p>

                    <p>
                        To see MORE of what we do, check out our Instagram page @bestofvcf ! Here you will find memes, podcasts, verses of encouragement, book reviews and more :)))
                    </p>

                </div>
            </div>
        );
    }
}

export default BESTPage;