import React, { Component } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

class MinistryThumbnail extends Component {
	render() {
		return (
			<Col>
				<Link to={this.props.link} className='ministry-thumbnail'>
					<img src={this.props.img} alt=""/>
					<div><span>{this.props.name}</span></div>
				</Link>
			</Col>
		);
	}
}

export default MinistryThumbnail;