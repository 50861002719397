import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
// import CarouselSlides from "../CarouselSlides";

class LawPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				{/* <CarouselSlides /> */}
				<div className="container">
					<h1>Law</h1>
					<p>
						Navigating life as a Law student and Christ follower becomes a lighter burden to carry, having found a community that seeks to “bear one another’s burdens, and thereby fulfil the law of Christ” (Galatians 6:2).
					</p>
					<p>
						We are a loving, warm and authentic community that has experienced the faithfulness of God. We treasure God’s presence and His Word above all else, and seek to love Him with our whole hearts.
					</p>

					<p>
						Through our activities, we are committed to discipling a generation of law students who will be fully devoted to the Lord, equipped to be His witnesses to the ends of the earth, and who will act justly, love mercy, and walk humbly with Him (Micah 6:8).
					</p>

					<p><b>Activities:</b>
						<ul>
							<li>Weekly Contact Group sessions (Wednesdays, 2-4 pm) for a time of worship, Bible Study and prayer</li>
							<li>Fellowship Teachings — talks by external speakers</li>
							<li>Outreach activities every semester to bless the wider community</li>
						</ul>
					</p>

					<p>
						<b>Visit Us</b><br />
						We welcome you with open arms! If you are interested to visit Law VCF, please fill up this form and we will get back to you shortly: <a href="https://tinyurl.com/visitlawvcf">https://tinyurl.com/visitlawvcf</a>
					</p>

					<p>
						<b>Contact Us</b><br />
						Please feel free to contact us for further queries: <a href="https://tinyurl.com/contactlawvcf">https://tinyurl.com/contactlawvcf</a>
					</p>

					<p>
						<b>Updates</b><br />
						For the latest updates, follow us on Instagram (@lawvcf): <a href="https://www.instagram.com/lawvcf/">https://www.instagram.com/lawvcf/</a>
					</p>
				</div>
			</div>
		);
	}
}

export default LawPage;