import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop";

class PGCFPage extends Component {
	render() {
		return(
			<div className="container">
				<ScrollToTop />
				<h1>Post Graduate Christian Fellowship</h1>
				{/*
				<p>Post Graduate Christian fellowship is a response to God’s call where He has placed us vocationally in 
					National University of Singapore. As a postgraduate ministry in NUS, we seek to fulfil His purposes 
					of touching lives and transforming our campus community through seminars and networking with Christian leaders.</p>
				<p>As a cross-disciplinary fellowship group, we also explore and live out the influence of the Good News on our data 
					and our ideas as Christian researchers. This postgraduate ministry seeks to gather like-minded graduate Christians 
					across the different faculties in NUS in godly Christian fellowship for service and growth together. 
					Our community is also a pillar of support and prayer for each other.</p>
				<p className="centered"><i>You are the light of the world. A town built on a hill cannot be hidden. Matthew 5:14</i></p>
				<p>Our meetings are held in the evenings, every fortnight. Please contact us at <a href="mailto:vcfsecretary@gmail.com">vcfsecretary@gmail.com</a> about joining our fellowship!</p>
				*/}

				<p>
					Finished your Bachelor’s degree but decided to keep studying in NUS and join VCF again? Wait no more! PGCF is here to journey with you.
				</p>
				<p>
					PGCF consists of postgraduate students (Masters/PhD) and research staff who seek to glorify God in our research and our work. We are building a community of Christ-like researchers who seek God's direction on how to redeem academia for the kingdom of God. We invite you to come together with us to grow in a deeper relationship with Christ!
				</p>
				<p>
					Some of PGCF activities include
					<ul>
						<li>
							Small group gatherings according to members’ preferred schedule every fortnight (worship, bible/topical study and prayers)
						</li>
						<li>
						Talks from professors from the university staff Christian fellowship
						</li>
						<li>
						Social gatherings to de-stress
						</li>
						<li>
						Outreach activities
						</li>
					</ul>
				</p>

				<p>
					Get in touch with us on telegram t.me/Chrispybread or t.me/luke_20 or email <a href="mailto:vcfsecretary@gmail.com">vcfsecretary@gmail.com</a> about joining the fellowship
				</p>
			</div>
		);
	}
}

export default PGCFPage;