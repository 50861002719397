import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class OpenPage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/faculties/bizcom/bizcom2.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/bizcom/bizcom3.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/bizcom/bizcom4.jpg" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>BizCom CF</h1>

					<p>
						BizCom CF seeks to provide a warm and welcoming environment for students in the Business and Computing faculties to fellowship with one another.
						We study the Word together, encourage one another, cheer each other up during tough seasons, and spur one another towards love and  good deeds. 
						We welcome all Biz/Computing students from all levels to join our community.
						Get to meet new friends but most importantly, get to know the Lord on a deeper level with us!
					</p>

					<p>
						Get in touch by dropping us a note at <a href="mailto:vcfsecretary@gmail.com">vcfsecretary@gmail.com</a> and we will reach out to you!
					</p>					
				</div>
			</div>
		);
	}
}

export default OpenPage;