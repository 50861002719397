import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class KentRidgePage extends Component {
    render() {
        const imgs = [
            <img src="/imgs/halls/kentridge/kentridge1.png" alt="" className="img-center" />,
        ];

        return (
            <div>
                <ScrollToTop />
				<CarouselSlides content={imgs}/>
                <div className="container">
                    <h1>Kent Ridge CF</h1>
                    <p>
                        Here in Kent Ridge CF, everyone is welcomed. We meet every week to have bible studies, topical discussions, worship and prayer. On top of that, 
                        we also have our usual suppers at Supper Stretch, occasional lunches, Christmas celebration and outreach events. 
                    </p>

                    <p>
                        KRCF is a comfortable space for anyone, no matter where they are in their faith, to learn, give praises and grow in their faith for Our Lord Father. Feel free to join us :)
                    </p>

                    <p>
                        If you have any other queries, do contact Shanon on Telegram @shanonkua !
                    </p>
                </div>
            </div>
        );
    }
}

export default KentRidgePage;