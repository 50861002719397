import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop";
import CarouselSlides from "../CarouselSlides";

class IFGPage extends Component {
    render() {
        const imgs = [
            <img src="/imgs/standingComms/IFG/IFG1.jpg" alt="" className="img-center" />,
            <img src="/imgs/standingComms/IFG/IFG2.jpg" alt="" className="img-center" />,
            <img src="/imgs/standingComms/IFG/IFG3.jpg" alt="" className="img-center" />,
            <img src="/imgs/standingComms/IFG/IFG4.jpg" alt="" className="img-center" />,
        ];
        return (
            <div>
                <ScrollToTop />
                <div className="container">
                    <h1>International Friendship Group (IFG)</h1>

                    <div style={{
                        textAlign: 'center', // <-- the magic
                        fontWeight: 'bold'
                    }}>
                        We are a standing committee of VCF that seeks to extend hospitality out of the overflow of who God is to us to the international students.<br /><br />
					We hope to mould the hearts of the VCF community to engage cross-culturally and forge friendships with international students.
				</div>
                    <div style={{ marginBottom: 60, marginTop: 30 }}>
                        <CarouselSlides content={imgs} />
                    </div>
                    <div style={styles.displayStyle}>
                        Leviticus 19:33-34 (ESV)
					</div>
                    <div style={styles.backgroundEffect} >
                        <p><sup>33</sup>“When a stranger sojourns with you in your land, you shall not do him wrong. <sup>34</sup>You shall treat the stranger who sojourns with you as the native among you, and you shall love him as yourself, for you were strangers in the land of Egypt: I am the Lord your God."</p>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        Through IFG, we hope our international friends will:<br />
                            1. Experience God’s love within the larger VCF community<br />
                            2. Come to know and to receive Christ as their Lord and Saviour (as the Lord leads)<br />

                        <br />Partner us in God’s work by praying for and reaching out to international students. Let us be a display of God’s character to the internationals on campus!
                        <br /><br />It’s time to put our faith into action!
                    </div>

                    <div style={styles.backgroundEffect} >
                        <p>
                            Like and follow us at our Facebook Page <a href="https://www.facebook.com/nusinternationalfriendshipgroup/">NUS International Friendship Group</a> and Instagram @ifg.nusvcf for more updates and events!
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

// font: 600 1.5rem/1.25 "Montserrat", sans-serif;
//backgroundColor: "#d3d3d3",
const styles = {
    displayStyle: {
        fontFamily: 'Montserrat',
        textAlign: 'center',
        fontSize: 30
    },
    backgroundEffect: {
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 30,
    }
}

export default IFGPage;