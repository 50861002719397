import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class USPPage extends Component {
	render() {
		return(
			<div>
                <ScrollToTop />
				<div className="container">
					<h1>USP</h1>
					<p>
                        USP Fellowship is committed to being an inter-denominational group of believers 
                        who gather regularly to worship the manifold wisdom and splendour of God. We 
                        see our Mid-week time together as a time of rest in Christ. We acknowledge that 
                        to have fellowship with His body comes hand in hand with fellowship with the 
                        Father, His son Jesus Christ (1 John 1:3) and the Holy Spirit. We are not a 
                        proxy for regular corporate worship in a church with the spiritual oversight 
                        of pastors and/or elders, but we recognise the need for us to lead consistent 
                        lives with the gospel everywhere we go and the need to submit to the authority 
                        of scripture. This is often challenging in university, and even more so when 
                        staying on campus &ndash; our role then, is to help each other walk in the light 
                        and not in darkness. Most of us happen to be either residing in Cinnamon College 
                        or are part of the USP, and so we gather as part of His body here.</p>
                    <p>
                        Join us for a time of fellowship every Wednesday, 9.15pm. Expect to sing songs 
                        of praise, read scripture together and a time of prayer. We look forward to meeting you! :)
                    </p>
				</div>
			</div>
		);
	}
}

export default USPPage;