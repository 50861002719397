import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-47058255-2');

ReactGA.set({ page: '/' });
ReactGA.pageview('/');

const history = require("history").createBrowserHistory();
history.listen((location, action) => {
    console.log(location.pathname)
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

ReactDOM.render((
    <Router history={history}>
        <App />
    </Router>
), document.getElementById("root"));