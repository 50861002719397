import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"
import CarouselSlides from "../CarouselSlides";

class SDEPage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/faculties/sde/sde1.PNG" alt="" className="img-center"/>,
			<img src="/imgs/faculties/sde/sde2.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/sde/sde3.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/sde/sde4.jpg" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>SDE</h1>
					<p>SDE CF is a motley bunch of friends who are a mix of friendly, chill, 
					awkward, serious, and edifying lovers of God. We meet weekly to fellowship and 
					have bible/topical studies! Through the tumultuous semesters, we want to be a place of rest, an anchor to God, 
					and be the salt and light to our school. :D</p>
					<p>Please contact us if you have any questions 
					or would like to find out more! :)
						<ul>
							<li>Email: sdevcf2020@gmail.com</li>
							<li>Telegram: @jlzq27 or @mokkery</li>
							<li>Biannual Recess Week Gatherings</li>
						</ul></p>
				</div>
			</div>
		);
	}
}

export default SDEPage;