import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class TemasekPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>Temasek</h1>
					<p>A family of like minded Christians who believe in staying accountable as we journey through uni together, loving each other and growing in scripture, all while managing the hustle and bustle of hall culture</p>
				</div>
			</div>
		);
	}
}

export default TemasekPage;