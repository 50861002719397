import React, { Component } from "react";
import { Row } from "reactstrap";
import ScrollToTop from "../Utils/ScrollToTop";

class ISCFPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>ISCF</h1>
					<Row>
						<img src='/imgs/ministries/iscf/pic1.png' alt=' ' />
					</Row>
					<p>Indonesian Students Christian Fellowship (ISCF) is an affiliate ministry under the Varsity Christian Fellowship.</p>
					<p>We are a group of undergraduate students in NUS managed by the Fellowship of Evangelical Students - Indonesian Ministry.</p>
					<p>This year NUS ISCF is striving to be "A Loving Community Inside, A Living Testimony Outside".</p>
					<p>Do join us for a fruitful fellowship with fellow Indonesian students and to grow together in Christ!</p>
					<p>Find us on Facebook: <a href = "https://www.facebook.com/groups/721235037948074/NUS"> NUS ISCF</a> or contact: Natalia @<a href="tel:96202179">96202179</a> </p>
				</div>
			</div>
		);
	}
}

export default ISCFPage;