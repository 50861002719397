import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop";
import CarouselSlides from "../CarouselSlides";
import { Row } from "reactstrap";

class MedNursePage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/faculties/mednurse/mednurse4.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/mednurse/mednurse5.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/mednurse/mednurse2.JPG" alt="" className="img-center"/>,
			<img src="/imgs/faculties/mednurse/mednurse1.jpg" alt="" className="img-center"/>,
			<img src="/imgs/faculties/mednurse/mednurse3.JPG" alt="" className="img-center"/>,
			<img src="/imgs/faculties/mednurse/mednurse7.jpg" alt="" className="img-center"/>,
		];
		return(
			<div>
				<CarouselSlides content={imgs}/>
				<ScrollToTop />
				<div className="container">
					<h1>Medicine & Nursing Christian Fellowship (MNCF)</h1>
					<Row>
						<img src='/imgs/faculties/mednurse/pic1.png' alt=' ' />
					</Row>

					<p>
						We are a community of Medicine & Nursing Christians (& those exploring the faith) that comes together to worship God and know more about Him. As a campus ministry, we usually gather once a week to have Bible studies and fellowship with one another.
					</p>

					<p>
						In the 1950s, several medical students who had a desire to see how the Kingdom of God may look like on campus and to guard against false-teachings gathered together for fellowship in order to disciple, encourage one another, exhort each other, stir up others, pray, and bring their worship unto God through their lives as medical students. The gathering grew and spread across the campus to involve other faculties over time to form VCF, FES and other groups.
					</p>

					<p>
						Now, MNCF is in the midst of a big umbrella group that our seniors had trail-blazed and had set for us edifying traditions and resources that we can feed on. This, we are beyond utmost very thankful to God for.
					</p>

					<p>
						I wonder what your story will be like? What would the Christians in your batch stir up? Will we hold fast to our kingdom identity beyond our rapidly changing name-tags on our badge-reels/lanyards as we progress through the years?
					</p>

					<p>
						Join us! We meet on Tuesday nights 7.30-9.30pm in school, and have dinner beforehand together!
					</p>

					<p>
						Do reach out to Dominic @dominicbryan or Valerie @valtang and follow our Telegram channel & Instagram <a href="https://www.instagram.com/mednursecf/">@mednursecf</a> for more details :)
					</p>
				</div>
			</div>
		);
	}
}

export default MedNursePage;