import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop";
import CarouselSlides from "../CarouselSlides";

class SEnTPage extends Component {
	render() {
		const imgs =[
			<img src="/imgs/standingComms/SEnT/sent1.jpg" alt="" className="img-center"/>,
			<img src="/imgs/standingComms/SEnT/sent2.jpg" alt="" className="img-center"/>,
			<img src="/imgs/standingComms/SEnT/pic1.png" alt="" className="img-center"/>,
		];
		return(
			<div>
				<ScrollToTop />
				<CarouselSlides content={imgs}/>
				<div className="container">
					<h1>Social Engagement Table (SEnT)</h1>
					{/*
					<Row>
							<img src='/imgs/standingComms/SEnT/pic1.png' alt=' ' />
					</Row>
					<p>The Social Engagement Table (SEnT) exists in the VCF body to highlight the need for Christian social engagement with the people and issues around us. We aim to express VCF’s conviction to be “effective salt and light in campus and society”. The world today is filled with brokenness and injustice, which weigh as a burden on God’s heart, just as it should on ours. Social issues are aplenty, and some can exist right at our doorstep. As God’s people, we are all called to show love and care to those who need it most - the vulnerable, the unloved, and the oppressed. SEnT aims to re-imagine and push the boundaries of social engagement. We aim to explore social issues through a Biblical perspective and act on a spiritual imperative. Social Engagement can exist as an extension or an overflow of God’s love to us, simply because He loved us first.</p>
					<p>“He has shown you, O mortal, what is good. And what does the LORD require of you? To act justly and to love mercy and to walk humbly with your God.”- Micah 6:8</p>
					<p>Keep a lookout for our workshops this academic year! For more information on SEnT, or if you are interested in joining our committee, events or organize a workshop, kindly contact <a href="mailto:vcfsecretary@gmail.com">vcfsecretary@gmail.com</a></p>
					*/}

					<p>
						“He has shown you, O mortal, what is good. And what does the LORD require of you? To act justly and to love mercy and to walk humbly with your God.”- Micah 6:8
						</p>

					<p>
						The Social Engagement Table (SEnT) exists in the VCF body to highlight the need for Christian social engagement with the people and issues around us. We aim to express VCF’s conviction to be “effective salt and light in campus and society”. The world today is filled with brokenness and injustice, which weigh as a burden on God’s heart, just as it should on ours. Social issues are aplenty, and some can exist right at our doorstep. As God’s people, we are all called to show love and care to those who need it most - the vulnerable, the unloved, and the oppressed. SEnT aims to re-imagine and push the boundaries of social engagement. We aim to explore social issues through a Biblical perspective and act on a spiritual imperative. Social Engagement can exist as an extension or an overflow of God’s love to us, simply because He loved us first.
						</p>

					<p>
						Keep a lookout for our activities this academic year by following @vcf.sent on Instagram! For more information on SEnT, or if you are interested in joining our committee, events or organize a workshop, kindly contact <a href="mailto:dennis.tan@u.nus.edu"> dennis.tan@u.nus.edu</a> or message t.me/dundundu on Telegram.
						</p>

				</div>
			</div>
		);
	}
}

export default SEnTPage;