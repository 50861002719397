import React, { Component } from "react";
import { Navbar, NavbarBrand, Collapse, NavbarToggler, Nav, NavLink, NavItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

/**
 * Carousel Bar on top of webpage.
 */
class Tabbar extends Component {
	constructor(props) {
		super(props);
		this.toggleAbout = this.toggleAbout.bind(this);
		this.toggleMinistries = this.toggleMinistries.bind(this);
		this.state = {
			aboutOpen: false,
			ministriesOpen: false,
			isOpen: false,
			width: window.innerWidth
		};
		this.props.toggleTab.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	componentWillMount() {
		window.addEventListener("resize", this.handleWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({width: window.innerWidth});
	}

	toggleMinistries() {
		this.setState({
			ministriesOpen: !this.state.ministriesOpen
		});
		this.props.toggleTab("ministries");
	}

	toggleAbout() {
		this.setState({
			aboutOpen: !this.state.aboutOpen
		});
		this.props.toggleTab("aboutUs");
	}

	toggle() {
		this.setState({isOpen: !this.state.isOpen});
	}

	render() {
		const width = this.state.width;
		const isMobile = width <= 930;
		const toggleTab = this.props.toggleTab;
		let brandName = null;
		// if (isMobile) {
		// 	brandName = <NavbarBrand><Link to="/"><img src={require("../img/logo-small.png")} alt="" />Varsity CF</Link></NavbarBrand>;
		// } else {
		// 	brandName = <NavbarBrand><Link to="/"><img src={require("../img/logo-small.png")} alt="" />Varsity Christian Fellowship</Link></NavbarBrand>;
		// }
		if (isMobile) {
			brandName = <NavbarBrand tag={Link} style={styles.navbarStyle} to="/" onClick={() => {toggleTab("home")}}><img src={require("../img/rsz_vcf_logo_final-01.png")} alt="" />Varsity CF</NavbarBrand>;
		} else {
			brandName = <NavbarBrand tag={Link} style={styles.navbarStyle} to="/" onClick={() => {toggleTab("home")}}><img src={require("../img/rsz_vcf_logo_final-01.png")} alt="" />Varsity Christian Fellowship</NavbarBrand>;
		}
			// <Navbar color="dark" light expand="md">
					// 
					// <Nav className="tabbar-styles" navbar>
					// <Nav className="ml-auto" navbar>
		return (
			<Navbar color="dark" light expand="md">
				{brandName}
				<NavbarToggler onClick={this.toggle} />
				<Collapse isOpen={this.state.isOpen} navbar>
					<Nav className="ml-auto" navbar>
						<NavItem>
							<NavLink tag={Link} to="/"
								className={classnames({active: this.props.activeTab === "home"})}
								onClick={() => {toggleTab("home");}}
							>
								Home
							</NavLink>
						</NavItem>
						<Dropdown nav isOpen={this.state.aboutOpen} toggle={this.toggleAbout}>
							<DropdownToggle className={classnames({active: this.props.activeTab === "aboutUs"})} nav caret>About Us</DropdownToggle>
							<DropdownMenu>
								<Link to="/vision"><DropdownItem>Our Vision</DropdownItem></Link>
								<Link to="/core-beliefs-structure"><DropdownItem>Our Core Beliefs &amp; Structure</DropdownItem></Link>
								<Link to="/fes"><DropdownItem>FES</DropdownItem></Link>
							</DropdownMenu>
						</Dropdown>
						<Dropdown nav isOpen={this.state.ministriesOpen} toggle={this.toggleMinistries}>
							<DropdownToggle className={classnames({active: this.props.activeTab === "ministries"})} nav caret>Ministries</DropdownToggle>
							<DropdownMenu>
								<Link to="/faculties"><DropdownItem>Faculties</DropdownItem></Link>
								<Link to="/halls-residences"><DropdownItem>Halls and Residences</DropdownItem></Link>
								<Link to="/standing-committees"><DropdownItem>Standing Committees</DropdownItem></Link>
								<Link to="/affiliates/pgcf"><DropdownItem>Post-Grad CF</DropdownItem></Link>
								<Link to="/affiliates/iscf"><DropdownItem>Indonesian Students' CF</DropdownItem></Link>
								<Link to="/affiliates/cvcf"><DropdownItem>中文团契 Chinese VCF</DropdownItem></Link>
								{/* <Link to="/exco"><DropdownItem>EXCO</DropdownItem></Link> */}
							</DropdownMenu>
						</Dropdown>
						{/*
						<NavItem>
							<NavLink tag={Link} to="/blog" 
								onClick={() => {toggleTab("blog");}}
								className={classnames({active: this.props.activeTab === "blog"})}
							>
								Blog
							</NavLink>
						</NavItem>
						*/}
						<NavItem>
							<NavLink tag={Link} to="/contact-us"
								onClick={() => {toggleTab("contactUs");}}
								className={classnames({active: this.props.activeTab === "contactUs"})}
							>
								Contact Us
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		);
	}
}

export default Tabbar;

const styles = {
	navbarStyle: {
		color: 'white'
	}
}