import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop"

class ShearesPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>Sheares</h1>
					<p>Sheares Hall Family Hall!
					<br />We hope that you will feel the warmth of a family joining us. If you are interested to know more about Christianity, please feel free to join us too :)</p>
					<p>Sheares VCF is a community of believers of various denominations residing within Sheares Hall. We have weekly gatherings where we will have praise and worship and bible/topical studies. On top of weekly gatherings, we hope to organise informal gatherings (e.g. games/prayer) as and when during the semester to bond, pray for, and encourage one another.</p>
				</div>
			</div>
		);
	}
}

export default ShearesPage;