import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
import ScrollToTop from "../Utils/ScrollToTop";

class Distinctive extends Component {
    
	render() {
		const imgUrl = "/imgs/distinctives-" + this.props.img + ".png"; 
		return (
			<Col xs="12" md="6" className="distinctive">
				<div style={ styles.imageStyle } >
					<img src={imgUrl} alt="" />
				</div>
				<h3>{this.props.name}</h3>
				<p>{this.props.children}</p>
			</Col>
		);
	}
}

class BeliefsPage extends Component {
	render() {
		return(
			<div>
				<ScrollToTop />
				<div className="container">
				<h1>Our Core Beliefs &amp; Structure</h1>
				<h2>Our Doctrinal Beliefs</h2>
				<ul>
					<li>The divine inspiration and infallibility of Holy Scripture, as originally given, and its supreme authority in all matters of faith and conduct. (2 Timothy 3:16, John 10:35)</li>
					<li>The unity of the Father, the Son and the Holy Spirit in the Godhead. (Matthew 28:19, 2 Corinthians 13:14)</li>
					<li>The universal sinfulness and guilt of human nature since the Fall, rendering man subject to God’s wrath and condemnation. (Romans 3:23, Romans 6:23)</li>
					<li>Redemption from the guilt, penalty and power of sin only through the shedding of the Blood in the sacrificial death (as our Substitute) of Jesus Christ, the Incarnate Son of God. (Ephesians 1:7)</li>
					<li>The physical resurrection of Jesus Christ from the dead, and His Ascension into Heaven. (1 Corinthians 15:3-4, Acts 1:9)</li>
					<li>The mediation between God and man only through the Lord Jesus Christ. (1 Timothy 2:5)</li>
					<li>The necessity of the work of the Holy Spirit to make the death of Jesus Christ effective to the individual sinner, granting him repentance towards God and faith in Jesus Christ. (John 3:5, Titus 3:5)</li>
					<li>The indwelling and work of the Holy Spirit in the believer. (1 Corinthians 3:16, 2 Thessalonians 2:13)</li>
					<li>The expectation of the personal return of the Lord Jesus Christ. (Acts 1:11)</li>
				</ul>

				<h2>Our Structure</h2>
				<div className='centered'><img src="/imgs/orgchart.jpg" alt="VCF Organisational Structure" /></div>

				<h2>Our Distinctives</h2>
				<Container>
					<Row>
						<Distinctive name='Evangelical' img="evangelical">
							VCF is committed to, and based on the entirety of Scripture.
						</Distinctive>
						<Distinctive name='Student-Led &amp; Student-Initiated' img="student">
							We are committed to student initiative, responsibility and leadership in our ministry.
						</Distinctive>
					</Row>
					<Row>
						<Distinctive name='Church-Oriented' img="church">
                            Our fellowship, as part of the universal Church, exists to provide a united witness for Christ on campus. 
                            We are committed to serve the Church by training and equipping students for service.
						</Distinctive>
						<Distinctive name='Inter-Denominational' img="interdenom">
							We are committed to celebrating the diversity of different denominations and churches. 
							This is done on the basis of their subscription to the doctrinal basis of the Fellowship and their commitment to the aims of the Fellowship.
						</Distinctive>
					</Row>
					<Row>
						<Distinctive name='Wholistic Witness' img="wholistic">
							We are committed to the good news of Jesus Christ. This necessarily includes proclamation and demonstration of the gospel 
							in all areas of life and across cultural and geographical boundaries.
						</Distinctive>
						<Distinctive name='Formacion' img="formacion">
							We are committed to discipleship through the wholistic development of the person, working towards maturity and Christ-likeness in all areas of life.
						</Distinctive>
					</Row>
				</Container>
				{/* <img src={require("../../img/Distinctives.png")} alt=""/> */}
				{/* <br /><br />
				<h3> <img src={require("../../img/distinctives-evangelical.png")} alt=""/> Evangelical</h3>
				<p>VCF is committed to, and based on the entirety of Scripture.</p>
				<h3> <img src={require("../../img/distinctives-student.png")} alt=""/> Student-Led and Student-Initiated</h3>
				<p>We are committed to student initiative, responsibility and leadership in our ministry.</p>
				<h3>Church-Oriented</h3>
				<p>Our fellowship, as part of the universal Church, exists to provide a united witness for Christ on campus. We are committed to serve the Church by training and equipping students for service.</p>
				<h3>Inter-Denominational</h3>
				<p>We are committed to celebrating the diversity of different denominations and churches. This is done on the basis of their subscription to the doctrinal basis of the Fellowship and their commitment to the aims of the Fellowship.</p>
				<h3>Wholistic Witness</h3>
				<p>We are committed to the good news of Jesus Christ. This necessarily includes proclamation and demonstration of the gospel in all areas of life and across cultural and geographical boundaries.</p>
				<h3>Formacion</h3>
				<p>We are committed to discipleship through the wholistic development of the person, working towards maturity and Christ-likeness in all areas of life.</p> */}

				</div>
			</div>
		);
	}
}

const styles = {
	imageStyle : {
		backgroundColor: "orange",
		height: 120,
		width: 120,
		borderRadius: "100%",
		resizeMode: "center",
		
		display: "flex",
		justifyContent: "center",
    	alignItems: "center",
    }
}

export default BeliefsPage;