import React, { Component } from "react";
import ScrollToTop from "../Utils/ScrollToTop";
import CarouselSlides from "../CarouselSlides";

class CVCFPage extends Component {
    render() {
        const imgs = [
			<img src="/imgs/ministries/cvcf/cvcf2.png" alt="" className="img-center" />,
		];

		return(
			<div>
				<ScrollToTop />
				<div className="container">
					<h1>中文团契 CVCF</h1>

                    <div style={{ marginBottom: 60, marginTop: 30 }}>
						<CarouselSlides content={imgs} />
					</div>

					<p>
					国大基督徒学生福音团契（简称CVCF）是VCF的独立分支之一，也是校园里唯一的华文团契！我们来自不同国家，华文水平也都不一样，但是我们都敬拜同一位神。我们坚持用华语，希望在校园里成为华族学生福音的管道，并展示出基督是万国之主，不是西方教义。无论你是习惯中文环境的信徒，或是想更加认识基督教的非信徒，都欢迎你参加我们的活动！
					</p>

					<p>
						Chinese Varsity Christian Fellowship (CVCF) is an autonomous branch of VCF that uses Chinese as the primary language! Naturally, we have brothers and sisters from countries across the Chinese-speaking world, but we also have a good number of local “bananas” among us. ;) Being the only Chinese-speaking fellowship in NUS, we aim to be a platform for the gospel towards the Chinese students on campus. If you have a burden for the Chinese people, or even if you are just curious to see what a Chinese fellowship looks like, you are welcome to join us in our activities!
					</p>

                    <p>
                        Instagram: <a href="https://www.instagram.com/nuscvcf/">@nuscvcf</a> <br />
						Facebook: <a href="https://www.facebook.com/nuscvcf/">@nuscvcf</a> <br />
						Email 电邮: <a href="mailto:cvcf.secretary@gmail.com">cvcf.secretary@gmail.com</a>
					</p>

					<p>
						如果你有任何问题或想知道更多，请随时与我们联系！<br/>
						Please feel free to contact us if you have any questions or would like to find out more! 
					</p>

					<h2>欢迎营 Welcome Camp 2021</h2>

					<p>
					一年一度的欢迎营将在8月12至14日 进行。你期待吗？今年的主题是《同契连枝》，我们会透过 约翰福音 15:1-17 来探讨主题。我们在校园生活中该如何常在主里面？还有，干嘛参加团契呢？无论你是新生或是 “老生”，相信你们会发现这个主题与你现在的生活很贴近。欢迎各位快快报名，真期待能够认识你们！
					</p>

					<p>
					Our annual Welcome Camp (conducted in Mandarin) is just around the corner! Aren’t you feeling excited? We have exciting games and an engaging and relatable series of talks lined up just for you, with plenty of time for making new friends and exploring what CVCF is all about! The talks will focus on John 15:1-17 to examine our camp theme, “同契连枝”. How can we continually abide in Christ, in the midst of our hectic university lives? What's the point of joining a Christian fellowship? Freshmen or seniors alike, these questions are relevant to any Christian student. What are you waiting for? The camp is open to both freshmen and seniors, so do sign up fast! We are excited to meet you all :D
					</p>

					<p>
						报名链接 Registration link: <a href="https://tinyurl.com/CVCF2021WC">https://tinyurl.com/CVCF2021WC</a>
					</p>

					<p><b>例常活动 Our Regular Activities</b></p>
					<p> <u>
						小组查经 Small Group Bible Study</u><br/>
						星期二 (Tuesdays) 7.00pm – 9.15pm
					</p>

					<p> <u>
						大组讲座 (Fellowship Teaching)</u><br/>
						星期四 (Thursdays) 7.00pm – 9.00pm
					</p>

                    <p> <u>
						启发课程 Alpha Course</u><br />
						第二学期（双语 Bilingual）<br />
						联络人 (Contact):<br />
						劼圣 (Jensen) 9119 0613<br />
						嘉惠 (Jia Hui) 8647 1505<br />
                    </p>

					<p>
						<b>今年的主题：常在主里面，反映神荣面</b><br/>
						<u>约翰福音15:4-5 </u><br/>
						你们要常在我里面，我也常在你们里面。枝子若不常在葡萄树上，自己就不能结果子；你们若不常在我里面，也是这样。我是葡萄树，你们是枝子。常在我里面的，我也常在他里面，这人就多结果子；因为离了我，你们就不能做什么。
					</p>

				</div>
			</div>
		);
	}
}

export default CVCFPage;